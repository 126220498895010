import { doLogin, setToken } from "../../plugins/api-service";

const state = {
  sessId: '',
};


const getters = {
};

const mutations = {
  setSessId(state, payload) {
    state.sessId = payload.result
  },
};

const actions = {
  async doLogin(context, params) {
    return await doLogin(params).then(response => {
      if(response.result) {
        setToken(response.result.token);
        if(!context.state.sessId) {
          context.commit('setSessId', { result: response.result.token });
        }
      }
      return response;
    }).catch((error) => {
      let message = error;

      if(error.response && error.response.data) {
        message = error.response.data;
      }

      return message;
    })
  },
  doLogout() {
    setToken('');

    return {
      success: true
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

<template>
  <div class="news-creation-page">
    <div class="save-button-container">
      <button class="button" @click="openNewsList">К списку новостей</button>
      <div v-if="saved" class="success-text">
        <span class="checkmark">&#10003;</span> Сохранено
      </div>
      <button class="button" @click="saveNews">Сохранить</button>
    </div>
    <input class="news-image-uploader" type="file" @change="uploadImageEvent" />

    <div class="news-images-container">
      <div class="news-image-block" v-if="imageUrl2X">
        <div
            class="news-image"
            :style="`background-image: url(${imageUrl2X}); width: 480px; height: 202px`"
        />
        <div class="news-image-caption">{{ "Dashboard Desktop" }}</div>
      </div>
      <div class="news-image-block" v-if="imageUrlMobile">
        <div
            class="news-image"
            :style="`background-image: url(${imageUrlMobile}); width: 164px; height: 202px`"
        />
        <div class="news-image-caption">{{ "Dashboard Mobile" }}</div>
      </div>
      <div class="news-image-block" v-if="imageUrl">
        <div
            class="news-image"
            :style="`background-image: url(${imageUrl}); width: 350; height: 148px`"
        />
        <div class="news-image-caption">{{ "News Page Desktop" }}</div>
      </div>
      <div class="news-image-block" v-if="imageUrlMobile2X">
        <div
            class="news-image news-image-resizable"
            :style="`background-image: url(${imageUrlMobile2X }); width: ${imageWidthMobile2X}px; height: 94px; max-width: 390px; min-width: 164px;`"
            @resize="updateImageWidthMobile2X"
        />
        <div class="news-image-caption">{{ "News Page Mobile" }}</div>
      </div>
    </div>
    <br>
    <div style="display:flex; align_items:center; gap:10px">
        <div v-for="lang in languages" :key="lang" class="language-selector">
            <input type="checkbox" :id="lang" :value="lang" v-model="enabledLanguages[lang]" />
            <label :for="lang">{{ languageNames[lang] }}</label>
        </div>
        <div id="all-languages-selector" class="button">
            <button @click="selectAllLanguages">Выбрать все</button>
        </div>
    </div>
    <br>
    <div v-show="enabledLanguages[lang]" v-for="lang in languages" :key="lang" class="news-block">
      <div class="language-name">
        {{ languageNames[lang] }}
        <button class="button" @click="autoTranslate(lang)" v-if="lang !== 'ru'">Автоперевод</button>
        <div v-if="finishedAutoTranslateAll && lang === 'ru'" class="success-text">
          <span class="checkmark">&#10003;</span> Готово!
        </div>
        <div v-if="startedAutoTranslateAll && lang === 'ru'" class="waiting-text">
          <div class="loading-spinner"></div> В процессе!
        </div>
        <button class="button" @click="autoTranslateAll()" v-if="lang === 'ru'">Автоперевод на все языки</button>
      </div>
      <div class="news-header">
        <input class="news-title" type="text" v-model="newsTitle[lang]" :placeholder="`Введите название новости (${languageNames[lang]})`" />
        <input class="news-date" type="date" v-model="newsDate"/>
      </div>
      <div :id="`editor-${lang}` "></div>
      <br>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {marketingUrl, createNews, editNews, getNewsByIndex, translateText, uploadImage} from '../plugins/api-service';

export default {
  data() {
    return {
      newsDate: '',
      newsTitle: { ru: '', en: '', es: '', de: '', fr: '' },
      newsText: { ru: '', en: '', es: '', de: '', fr: '' },
      imagePath: '',
      imagePath2X: '',
      imagePathMobile: '',
      imagePathMobile2X: '',
      imageUrl: '',
      imageUrl2X: '',
      imageUrlMobile: '',
      imageUrlMobile2X: '',
      languages: ['ru', 'en', 'es', 'de', 'fr'],
      languageNames: {
        ru: 'Русский',
        en: 'English',
        es: 'Español',
        de: 'Deutsch',
        fr: 'Français'
      },
      enabledLanguages: {
        ru: true,
        en: false,
        es: false,
        de: false,
        fr: false
      },
      saved: false,
      finishedAutoTranslateAll: false,
      startedAutoTranslateAll: false,
      imageWidthMobile2X: 390
    };
  },
  mounted() {
    this.quills = {};
    this.languages.forEach(lang => {
      const quill = new Quill(`#editor-${lang}`, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      });
      quill.on('text-change', () => {
        this.newsText[lang] = quill.root.innerHTML;
      });
      this.quills[lang] = quill;
    });

    if (this.$route.name === 'NewsEdit') {
      this.fetchNews(this.$route.params.index);
    } else {
      this.newsDate = this.formatDate(new Date());
    }
  },
  methods: {
    async fetchNews(index) {
      await getNewsByIndex(index).then(response=>{
        if(response?.success) {
          this.newsTitle = {
            ru: response.result.ru.title,
            en: response.result.en.title,
            es: response.result.es.title,
            de: response.result.de.title,
            fr: response.result.fr.title
          };
          this.newsText = {
            ru: response.result.ru.text,
            en: response.result.en.text,
            es: response.result.es.text,
            de: response.result.de.text,
            fr: response.result.fr.text
          };
          console.log(response.result);
          this.enabledLanguages = {
            ru: response.result.ru.is_enabled,
            en: response.result.en.is_enabled,
            es: response.result.es.is_enabled,
            de: response.result.de.is_enabled,
            fr: response.result.fr.is_enabled
          };
          this.imagePath = response.result.ru.image_path;
          this.imagePath2X = response.result.ru.image_path_2x;
          this.imagePathMobile = response.result.ru.image_path_mobile;
          this.imagePathMobile2X = response.result.ru.image_path_mobile_2x;
          this.imageUrl = marketingUrl(this.imagePath);
          this.imageUrl2X = marketingUrl(this.imagePath2X);
          this.imageUrlMobile = marketingUrl(this.imagePathMobile);
          this.imageUrlMobile2X = marketingUrl(this.imagePathMobile2X);
          this.newsDate = this.formatDate(new Date(response.result.ru.created_at * 1000));
          for (let lang in this.quills) {
            this.quills[lang].root.innerHTML = this.newsText[lang];
          }
        } else {
          alert('Произошла ошибка при загрузке новостей');
        }
      });
    },
    formatDate(date) {
      let month = '' + (date.getMonth() + 1),
          day = '' + date.getDate(),
          year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    uploadImageEvent(event) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      // Отправка запроса на API для загрузки изображения
      // После успешной загрузки сохраните полученный путь в this.imagePath

      uploadImage(formData).then(response=>{
        if(response?.success) {
          this.imagePath = response.result.image_path;
          this.imagePath2X = response.result.image_path_2x;
          this.imagePathMobile = response.result.image_path_mobile;
          this.imagePathMobile2X = response.result.image_path_mobile_2x;
          this.imageUrl = marketingUrl(this.imagePath);
          this.imageUrl2X = marketingUrl(this.imagePath2X);
          this.imageUrlMobile = marketingUrl(this.imagePathMobile);
          this.imageUrlMobile2X = marketingUrl(this.imagePathMobile2X);
        } else {
          alert('Произошла ошибка при загрузке изображения');
        }
      });
    },
    saveNews() {
      let newsDate = new Date(this.newsDate);
      let utcTimestamp = Date.UTC(newsDate.getUTCFullYear(),
          newsDate.getUTCMonth(),
          newsDate.getUTCDate(),
          newsDate.getUTCHours(),
          newsDate.getUTCMinutes(),
          newsDate.getUTCSeconds(),
      );
      console.log(newsDate);
      const newsData = {
        created_at: utcTimestamp / 1000,
        titles: this.newsTitle,
        texts: this.newsText,
        enabled_languages: this.enabledLanguages,
        image_path: this.imagePath,
        image_path_2x: this.imagePath2X,
        image_path_mobile: this.imagePathMobile,
        image_path_mobile_2x: this.imagePathMobile2X
      };
      if (this.$route.name === 'NewsEdit') {
        editNews(this.$route.params.index, newsData).then(response=>{
          if (!response?.success) {
            alert('Произошла ошибка при сохранении новости');
          }
        });
      } else {
        createNews(newsData).then(response=> {
          if (response?.success) {
            history.pushState(null, '', `${window.location.origin}/news/edit/${response.result.index}`);
            this.$router.push({ name: 'NewsEdit', params: { index: response.result.index } });
          } else{
            alert('Произошла ошибка при сохранении новости');
          }
        });
      }
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 2000);
    },
    autoTranslate(lang) {
      return Promise.all([
        translateText({
          from_lang: 'ru',
          target_lang: lang,
          text: this.newsText.ru
        }).then(response=>{
          if(response?.success) {
            this.newsText[lang] = response.result;
            this.quills[lang].root.innerHTML = response.result;
          } else {
            alert('Произошла ошибка при автопереводе');
          }
        }),
        translateText({
          from_lang: 'ru',
          target_lang: lang,
          text: this.newsTitle.ru
        }).then(response=>{
          if(response?.success) {
            this.newsTitle[lang] = response.result;
          } else {
            alert('Произошла ошибка при автопереводе');
          }
        })
      ]);
    },
    autoTranslateAll() {
      this.startedAutoTranslateAll = true;
      Promise.all(this.languages.filter(lang => lang !== 'ru').map(this.autoTranslate))
      .then(() => {
        this.startedAutoTranslateAll = false;
        this.finishedAutoTranslateAll = true;
        setTimeout(() => {
          this.finishedAutoTranslateAll = false;
        }, 3000);
      })
    },
    prepareHTMLFormattingForBackend(newsText) {
      const newsTextForBackend = {};
      for (let lang in newsText) {
        console.log(newsText[lang]);
        newsTextForBackend[lang] = newsText[lang]
            .replace(/<br>/g, '\n')
            .replace(/\n<\/p><p>/g, '\n')
            .replace(/<\/p><p>/g, '\n')
            .replace(/<p>/g, '')
            .replace(/<\/p>/g, '');
        console.log(newsTextForBackend[lang]);
      }
      return newsTextForBackend;
    },
    openNewsList() {
      window.open(`${window.location.origin}/news`, '_self');
    },
    updateImageWidthMobile2X(event) {
      this.imageWidthMobile2X = event.target.offsetWidth;
    },
    selectAllLanguages() {
      if (Object.values(this.enabledLanguages).every(value => value)) {
        for (let lang in this.enabledLanguages) {
          this.enabledLanguages[lang] = false;
        }
      } else {
        for (let lang in this.enabledLanguages) {
          this.enabledLanguages[lang] = true;
        }
      }
    }
  }
};
</script>

<style scoped>
.news-creation-page {
  width: 98%;
  margin: 1% auto;
}
.news-header {
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news-title {
  width: 90%;
  font-size: 16px;
  font-weight: normal;
  display: block;
  margin-right: 10px;
  padding: 1px;
}
.news-date {
  width: 10%;
  font-size: 16px;
  font-weight: normal;
  display: block;
  padding: 1px;
}
.news-image-uploader {
  width: 99%;
  display: block;
  margin: 10px auto;
}
.news-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.news-image-block {
  margin-bottom: 20px;
}
.news-image {
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  max-width: 100%;
  width: 350px;
  height: 148px;
  background: center no-repeat;
  background-size: cover;
  margin-right: 20px;
}
.news-image-caption {
  text-align: center;
  font-size: 16px;
  color: #333;
}
.news-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.6) 52.6%, rgba(0, 0, 0, 0) 100%);
}
.news-image-resizable {
  resize: horizontal;
  overflow: auto;
}
.news-image-resizable::before {
  cursor: ew-resize;
}
.news-block {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.language-name {
  text-align: right;
  font-size: 17px;
}
[id^="editor-"] {
  min-height: 75px;
  width: 100%;
}
.save-button-container {
  position: absolute;
  right: 20px;
  top: 20px;
}
.button {
  padding: 5px 5px;
  margin-left: 5px;
  background-color: rgba(21, 26, 47, 0.75);
  color: white;
}
.button:hover {
  background-color: rgba(21, 26, 47, 1);
}
.success-text {
  color: green;
  display: inline-block;
  margin-left: 10px;
}
.waiting-text {
  color: orange;
  display: inline-block;
  margin-left: 10px;
}
.checkmark {
  display: inline-block;
  margin-right: 5px;
}
.loading-spinner {
  border: 3px solid rgba(255, 165, 0, 0.2);
  border-top: 3px solid rgba(255, 165, 0, 1);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.language-selector {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>

<template>
    <v-container fluid class="ma-4">
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="search"
                :headers="headersCols"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
                v-model:expanded="expanded"
                show-expand
                single-expand

        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                                v-model="search.id"
                                variant="outlined"
                                hide-details
                                type="number"
                                placeholder="Search user id..."
                                class="ma-1"
                                @input="loadItems"
                                density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.first_name"
                                hide-details
                                variant="outlined"
                                placeholder="Search name..."
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="search.type"
                                hide-details
                                variant="outlined"
                                :items="types"
                                return-object
                                placeholder="Search type..."
                                class="ma-1"
                                label="Search type..."
                                item-text="title"
                                density="compact"
                                multiple
                                :disabled="custom_filter"
                                @update:modelValue="loadItems"
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="search.reward_source"
                          hide-details
                          variant="outlined"
                          :items="reward_sources"
                          return-object
                          placeholder="Reward source..."
                          class="ma-1"
                          label="Reward source..."
                          item-text="title"
                          density="compact"
                          multiple
                          :disabled="custom_filter"
                          @update:modelValue="loadItems"
                      ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="custom_filter"
                                hide-details
                                variant="outlined"
                                :items="filterTypes"
                                return-object
                                placeholder="Custom filter..."
                                class="ma-1"
                                label="Custom filter..."
                                item-text="title"
                                density="compact"
                                clearable
                                @update:modelValue="loadItems"
                        ></v-select>
                    </v-col>
                    <v-col class="align-center justify-space-between d-flex ml-8" v-if="0">
                        <a :href="`/transactions?${search.id ? `id=${search.id}&` : ''}reward_source=trading&reward_source=pair_bonus&`">Trading rewards</a>
                        <a :href="`/transactions?${search.id ? `id=${search.id}&` : ''}reward_source=partner_plan_purchase&reward_source=pair_bonus&reward_source=leader_pool&reward_source=manual&reward_source=pre_start_pool_bonus&reward_source=fast_team_bonus`">Activation rewards</a>
                        <a :href="`/transactions?${search.id ? `id=${search.id}&` : ''}type=account_transfer&type=withdrawal&type=deposit&type=reward&type=pricing_plan_purchase&reward_source=partner_plan_purchase&reward_source=pair_bonus&reward_source=leader_pool&reward_source=manual&reward_source=pre_start_pool_bonus&reward_source=fast_team_bonus&min_abs_amount=1`">Marketing filter</a>
                        <a :href="`/transactions?${search.id ? `id=${search.id}&` : ''}type=trading_fee&type=reward&reward_source=trading&reward_source=pair_bonus&max_abs_amount=5`">Trading filter</a>
                        <a href="/transactions">Reset filters</a>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, toggleExpand, isExpanded }">
                <td v-if="item.selectable.linked_transactions" class="text-start">
                    <v-icon
                            :style="{'transform: rotate(180deg)': isExpanded(item)}"
                            @click="toggleExpand(item)"
                            :class="{'v-data-table__expand-icon--active' : isExpanded(item)}"
                    >mdi-chevron-down</v-icon>
                </td>
            </template>
            <template v-slot:[`item.user.id`]="{ item }">
                <router-link :to="`/users?id=${item.selectable.user.id}`">{{ item.selectable.user.id }}</router-link>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ moment(item.selectable.created_at * 1000).format('DD.MM.YYYY HH:mm:ss', true) }}
            </template>
            <template v-slot:[`item.user.first_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.first_name}`">{{ item.selectable.user.first_name }}</router-link>
            </template>
            <template v-slot:[`item.user.last_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.last_name}`">{{ item.selectable.user.last_name }}</router-link>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                {{ types_map[item.selectable.type] }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.amount, 4) }}
                <v-icon v-if="!item.selectable.is_internal">mdi-file-tree</v-icon>
            </template>
            <template v-slot:[`item.reward_source_caption`]="{ item }">
                {{ item.selectable.reward_source_caption ? item.selectable.reward_source_caption : '-' }}
            </template>
            <template v-slot:[`item.reward_source`]="{ item }">
                {{ item.selectable.reward_source ? rewardSourcesMap[item.selectable.reward_source] : '-' }}
            </template>
            <template v-slot:[`item.is_cashback`]="{ item }">
                {{ item.selectable.is_cashback ? 'Да' : 'Нет' }}
            </template>
            <template v-slot:expanded-row="{ item }">
                <tr class="v-data-table__tr v-data-table__tr--inner" v-if="item.selectable.linked_transactions">
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05); height: 40px"  v-for="headerItem in headersCols" :key="headerItem.title">{{ headerItem.title }}</td>
                </tr>
                <tr class="v-data-table__tr" v-for="(innerItem, index) in item.selectable.linked_transactions" :key="index">
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        {{ innerItem['id'] }}
                    </td>
                    <td class="v-data-table__td" style="text-transform: capitalize;background: rgba(0,0,0,.05);height: 40px;">
                        {{ innerItem['type'] }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        {{ innerItem['account_type'] }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        {{ moment(innerItem.created_at * 1000).format('DD.MM.YYYY HH:mm:ss', true) }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        ${{ parseFloat(innerItem['amount']) }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        <router-link :to="`/users?name=${innerItem.user.first_name}`">{{ innerItem['user']['first_name'] }}</router-link>
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        <router-link :to="`/users?name=${innerItem.user.last_name}`"> {{ innerItem['user']['last_name'] }}</router-link>
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        <router-link :to="`/users?id=${innerItem.user.id}`">{{ innerItem['user']['id'] }}</router-link>
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        {{ innerItem['reward_source'] ? rewardSourcesMap[innerItem['reward_source']] : '-' }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                        {{ innerItem['is_cashback'] ? 'Да' : 'Нет' }}
                    </td>
                    <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px; text-align: right">
                        {{ innerItem['reward_source_caption'] ? innerItem['reward_source_caption'] : '-' }}
                    </td>
                </tr>
            </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
  import { getTransactions } from "../plugins/api-service";
  import moment from 'moment';
  import currencyFormatter from "../mixins/currencyFormatter"

  const rewardSourcesMap = {
    'trading': 'Trading',
    'pair_bonus': 'Pair Bonus',
    'partner_plan_purchase': 'Partner Upgrade/Activation',
    'leader_pool': 'Leader Pool',
    'manual': 'Manual',
    'pre_start_pool_bonus': 'Pre Start Pool Bonus',
    'fast_team_bonus': 'Fast Team Bonus',
    'points_reward': 'World Wide Bonus',
  };

  export default {
    name: 'UsersTransactions',
    mixins: [currencyFormatter],
    data: () => ({
      itemsPerPage: 15,
      rewardSourcesMap,
      headersCols: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        { title: 'Тип транзакции', key: 'type', align: 'start' },
        { title: 'Тип аккаунта', key: 'account_type', align: 'start' },
        { title: 'Создана', key: 'created_at', align: 'start', sortable: false },
        { title: 'Сумма', key: 'amount', align: 'start' },
        { title: 'Имя', key: 'user.first_name', align: 'start', sortable: false },
        { title: 'Фамилия', key: 'user.last_name', align: 'start', sortable: false },
        { title: 'User ID', key: 'user.id', align: 'start', sortable: false },
        { title: 'Тип вознаграждения', key: 'reward_source', align: 'start', sortable: false },
        { title: 'Кэшбек', key: 'is_cashback', align: 'start', sortable: false },
        { title: 'Ручной бонус', key: 'reward_source_caption', align: 'end', sortable: false }
      ],
      expanded: [],
      filterTypes: [{
        title: 'trading rewards',
        value: 'trading_rewards'
      },
        {
          title: 'activation rewards',
          value: 'activation_rewards'
        },
        {
          title: 'manual rewards',
          value: 'manual_rewards'
        },
        {
          title: 'marketing filter',
          value: 'marketing'
        },
        {
          title: 'trading filter',
          value: 'trading'
        },
        {
          title: 'non-trading rewards',
          value: 'non_trading_rewards'
        },
        {
          title: 'cashback rewards',
          value: 'cashback_rewards'
        }
      ],
      types: [
        {
          title: 'Reward',
          value: 'reward'
        },
        {
          title: 'Deposit',
          value: 'deposit'
        },{
          title: 'Withdrawal',
          value: 'withdrawal'
        },{
          title: 'Account Transfer',
          value: 'account_transfer'
        },{
          title: 'Pricing Plan Purchase',
          value: 'pricing_plan_purchase'
        },{
          title: 'Trading Fee',
          value: 'trading_fee'
        }],
      reward_sources: [
        {
          title: 'Fast Team Bonus',
          value: 'fast_team_bonus'
        },
        {
          title: 'World Wide Bonus',
          value: 'points_reward'
        },
        {
          title: 'Pair Bonus',
          value: 'pair_bonus'
        },
        {
          title: 'Manual',
          value: 'manual'
        },
        {
          title: 'Partner Upgrade/Activation',
          value: 'partner_plan_purchase'
        },
        {
          title: 'Trading',
          value: 'trading'
        },
        {
          title: 'Pre Start Pool Bonus',
          value: 'pre_start_pool_bonus'
        },
      ],
      types_map: {
        'reward': 'Reward',
        'deposit': "Deposit",
        'withdrawal': 'Withdrawal',
        'account_transfer': 'Account Transfer',
        'pricing_plan_purchase': 'Pricing Plan Purchase',
        'trading_fee': 'Trading Fee'
      },
      custom_filter: null,
      serverItems: [],
      loading: true,
      totalItems: 99999,
      page: 1,
      search: {
        id: null,
        first_name: '',
        type: [],
        reward_source: []
      },
      sort: {}
    }),
    mounted: function () {
      if (this.$route.query.type) {
        if (Array.isArray(this.$route.query.type)) {
          if (this.$route.query.type.includes('reward')) {
            this.search.type.push({
              value: 'reward'
            });
          }

          for (let type of this.types) {
            for (let queryType of this.$route.query.type) {
              if (type.value === queryType) {

                if (type === 'trading' || type === 'pair_bonus') {
                  type = 'reward';
                }
                this.search.type.push(type);
              }
            }
          }
        } else {
          for (let type of this.types) {
            if (type.value === this.$route.query.type) {
              this.search.type.push(type);
            }
          }
        }
      }

      if (this.$route.query.id) {
        this.search.id = this.$route.query.id;
      }

      this.loadItems()
    },
    methods: {
      moment,
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        this.loading = true;

        let page = params?.page || 1;

        let requestParams = new URLSearchParams();

        requestParams.append('offset', (page - 1) * (params?.itemsPerPage || this.itemsPerPage));
        requestParams.append('limit', this.itemsPerPage === -1 ? this.totalItems : this.itemsPerPage);

        if(this.search.id || this.$route.params.id) {

          requestParams.append('user_id', this.search.id || this.$route.query.id);
        }

        if(this.$route.query.min_abs_amount) {
            requestParams.append('min_abs_amount', this.$route.query.min_abs_amount);
        }

        if(this.$route.query.max_abs_amount) {
            requestParams.append('max_abs_amount', this.$route.query.max_abs_amount);
        }

        if(this.search.first_name) {
          requestParams.append('user_name_contains', this.search.first_name)
        }

        if(this.custom_filter) {
          this.search.type = [];
          requestParams.append('custom_filter', this.custom_filter.value);
        }

        if(this.reward_sources.length && !this.custom_filter) {
          for(let reward_source in this.search.reward_source) {
            requestParams.append('reward_source', this.search.reward_source[reward_source]?.value);
          }
        }

        if(this.search.type.length && !this.custom_filter) {
          for(let type in this.search.type) {

            // if(this.search.type[type]?.value === 'trading' || this.search.type[type]?.value === 'pair_bonus') {
            //   requestParams.append('reward_source', this.search.type[type]?.value);
            //   type = 'reward';
            //   if(requestParams.getAll('type').includes(type)) {
            //     requestParams.append('type', type);
            //   }
            // } else {
            //   if(this.$route.query.reward_source) {
            //     if(Array.isArray(this.$route.query.reward_source)) {
            //       for(let reward_source of this.$route.query.reward_source) {
            //         if(!requestParams.getAll('reward_source').includes(reward_source)) {
            //           requestParams.append('reward_source', reward_source);
            //         }
            //       }
            //     } else {
            //       if(requestParams.getAll('reward_source').includes(this.$route.query.reward_source)) {
            //         requestParams.append('reward_source', this.$route.query.reward_source);
            //       }
            //     }
            //   }
              requestParams.append('type', this.search.type[type]?.value);
            // }
          }
        }

        if(this.$route.query.reward_source) {
          if(Array.isArray(this.$route.query.reward_source)) {
            for(let reward_source of this.$route.query.reward_source) {
              if(!requestParams.getAll('reward_source').includes(reward_source)) {
                requestParams.append('reward_source', reward_source);
              }
            }
          } else {
            if(requestParams.getAll('reward_source').includes(this.$route.query.reward_source)) {
              requestParams.append('reward_source', this.$route.query.reward_source);
            }
          }
        }

        if(this.sort.order_direction && this.sort.order_by) {
          requestParams.append('order_by', params.order_by);
          requestParams.append('order_direction', params.order_direction);
        }

        if(params?.order_direction && params?.order_by) {
          requestParams.append('order_by', params.order_by);
          requestParams.append('order_direction', params.order_direction);
        }

        await getTransactions(requestParams).then(response=>{
          if(response?.success) {
            this.serverItems = response.result.items;
            this.totalItems = response.result.total;
            this.loading = false;
          }
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
    .adm-container {
        min-height: calc(100vh - 64px);
    }
</style>
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import Notifications from '@kyvg/vue3-notification';
import * as labsComponents from 'vuetify/labs/components';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents
  },
  directives
});

const token = localStorage.getItem('token');

if(!token) {
  router.push('/sign-in');
}

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  if(!token) {
    if(to.name !== 'SignIn') {
      next({name: 'SignIn'});
    } else {
      next();
    }
  } else {
    if(to.name !== 'SignIn' && to.name !== 'home') {
      next();
    } else {
      next({name: 'Users'})
    }
  }
});

export const adminApp = createApp(App);

adminApp.component('VueDatePicker', VueDatePicker);

adminApp.use(store)
  .use(router)
  .use(vuetify)
  .use(Notifications)
  .mount('#app');

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Users from '../views/Users.vue'
import UserLevels from '../views/UserLevels.vue'
import UserTransactions from '../views/UserTransactions.vue'
import Accounts from '../views/Accounts.vue'
import Results from '../views/Results.vue'
import NewsView from "../views/NewsView.vue";
import NewsCreateEdit from "../views/NewsCreateEdit.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name: 'Users',
        path: 'users',
        component: Users,
      },
      {
        name: 'UsersById',
        path: 'users/:id',
        component: Users,
      },
      {
        name: 'UserLevels',
        path: 'user-levels',
        component: UserLevels,
      },
      {
        name: 'UserTransactions',
        path: 'transactions',
        component: UserTransactions,
      },
      {
        name: 'UserTransactionsById',
        path: 'transactions/:id',
        component: UserTransactions,
      },
      {
        name: 'Accounts',
        path: 'accounts',
        component: Accounts,
      },
      {
        name: 'AccountsById',
        path: 'accounts/:id',
        component: Accounts,
      },
      {
        name: 'News',
        path: 'news',
        component: NewsView,
      },
      {
        name: 'NewsCreate',
        path: 'news/create',
        component: NewsCreateEdit,
      },
      {
        name: 'NewsEdit',
        path: 'news/edit/:index',
        component: NewsCreateEdit,
      },
      {
        name: 'Results',
        path: 'results',
        component: Results,
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignIn.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition;

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      return { selector: to.hash };
    }

    // scroll to top by default
    return { top: 0 };
  }
})

export default router

<template>
    <v-toolbar color="indigo-darken-3">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Marketing Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="doLogout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-toolbar>
    <v-layout style="position: relative" class="adm-container">
      <v-navigation-drawer
              floating
              v-model="drawer"
              temporary
              absolute
      >
        <v-list
                density="compact"
                nav
        >
          <v-list-item
                  prepend-icon="mdi-account-group"
                  title="Пользователи"
                  value="/users"
                  to="/users"
          ></v-list-item>
          <v-list-item
                  prepend-icon="mdi-format-list-checkbox"
                  title="Счета"
                  value="accounts"
                  to="/accounts"
          ></v-list-item>
          <v-list-item
                  prepend-icon="mdi-handshake-outline"
                  title="Операции"
                  value="transactions"
                  to="/transactions"
          ></v-list-item>
          <v-list-item
                  prepend-icon="mdi-check-outline"
                  title="Статусы"
                  value="user-levels"
                  to="/user-levels"
          ></v-list-item>
          <v-list-item
                  prepend-icon="mdi-currency-usd"
                  title="Результаты"
                  value="results"
                  to="/results"
          ></v-list-item>
          <v-list-item
                  prepend-icon="mdi-newspaper-variant-multiple"
                  title="Новости"
                  value="news"
                  to="/news"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-layout>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    doLogout() {
      this.$store.dispatch('user/doLogout').then(()=>{
        this.$router.push('/sign-in');
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .adm-container {
    min-height: calc(100vh - 64px);
  }
</style>
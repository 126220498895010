export default {
  methods: {
    getFormattedCurrency(value, accuracy) {
      if(value) {
        return parseFloat(parseFloat(value).toFixed(accuracy))
      } else {
        return parseFloat(value);
      }
    },
    changeItemsPrePage(value) {
      this.itemsPerPage = value;
      this.loadItemsPage(1);
    },
  }
}
<template>
  <div>
    <button class="create-news-button" @click="openCreateNews">Создать новость</button>
    <div class="news-item" v-for="news in newsList" :key="news.index">
      <div class="news-image" v-if="news.imageUrl" :style="`background-image: url(${news.imageUrl})`"></div>
      <div class="news-header">
        <div class="news-title">{{  news.title  }}</div>
        <div class="news-date">{{ new Date(news.created_at * 1000).toDateString() }}</div>
      </div>
      <div class="news-text" v-html="truncateText(formatText(news.text))"></div>
      <div class="news-controls">
        <button @click="openEditNews(news.index)">Редактировать</button>
        <button @click="confirmDelete(news.index)">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteNews, getNews, marketingUrl} from "../plugins/api-service";
export default {
  data() {
    return {
      newsList: [],
      maxCharCount: 1024
    };
  },
  mounted() {
    this.updateMaxCharCount();
    window.addEventListener('resize', this.updateMaxCharCount);
    this.fetchNews();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateMaxCharCount);
  },
  methods: {
    async fetchNews() {
      await getNews().then(response=>{
        if(response?.success) {
          this.newsList = response.result.items.map(newsItem => ({
            ...newsItem,
            imageUrl: marketingUrl(newsItem.image_path)
          }));
        } else {
          alert('Произошла ошибка при загрузке новостей');
        }
      });
    },
    openCreateNews() {
      window.open(`${window.location.origin}/news/create`, '_self');
    },
    openEditNews(index) {
      window.open(`${window.location.origin}/news/edit/${index}`, '_self');
    },
    async deleteNews(index) {
      await deleteNews(index).then(response=>{
        if(response?.success) {
          this.newsList = this.newsList.filter(news => news.index !== index);
          alert('Новость удалена успешно');
        } else {
          alert('Произошла ошибка при удалении новости');
        }
      });
    },
	formatText(text) {
      // replace \n with <br>
    // return text.replace('\\n', '<br>');
    return text.replace(/\n|\r|\r\n|\\r|\\n|\\r\\n/g, '<br>');
    },
  updateMaxCharCount() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.maxCharCount = 256;
    } else {
      this.maxCharCount = 1024;
    }
  },
	truncateText(text) {
    return text.length > this.maxCharCount ? text.substring(0, this.maxCharCount - 3) + '...' : text;
	},
	confirmDelete(index) {
      if (window.confirm('Вы уверены, что хотите удалить эту новость?')) {
        this.deleteNews(index);
      }
    },
  }
};
</script>

<style scoped>
.news-item {
  padding: 10px;
  margin-bottom: 10px;
  overflow: auto;
  border-bottom: 5px solid rgba(21, 26, 47, 0.8);
}
.news-image {
  position: relative;
  max-width: 100%;
  width: 350px;
  height: 148px;
  background: center no-repeat;
  background-size: cover;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .news-image {
    position: relative;
    max-width: 100%;
    width: 360px;
    height: 94px;
    background: center no-repeat;
    background-size: cover;
    float: none;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.news-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.6) 52.6%, rgba(0, 0, 0, 0) 100%);
}
.news-header {
  display: flex;
  justify-content: space-between
}
@media (max-width: 768px) {
  .news-header {
    display: block;
  }
}
.news-title {
  margin-bottom: 15px;
  font-size: 1.3em;
  font-weight: bold;
}
.news-text {
  margin: 10px 0;
}
.news-date {
  font-size: 0.9em;
  color: #666;
}
.news-controls {
  display: flex;
  justify-content: flex-end; /* Выравнивает кнопки по правому краю */
}
.news-controls button {
  margin-right: 10px; /* Добавляет отступ справа для всех кнопок, кроме последней */
  background-color: rgba(21, 26, 47, 0.8);
  color: white;
  font-size: 0.9em;
  padding: 7px 7px;
  
}
.news-controls button:hover {
  background-color: rgba(21, 26, 47, 1);
}
.news-controls button:last-child {
  margin-right: 0; /* Убирает отступ справа для последней кнопки */
}
.create-news-button {
  margin-bottom: 10px;
  width: 100%;
  padding: 30px 0;
  font-size: 1.1em;
  background-color: rgba(21, 26, 47, 0.8);
  color: white;
}
.create-news-button:hover {
  background-color: rgba(21, 26, 47, 1);
}
</style>

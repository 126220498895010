import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

const qs = require('qs');
// let host = location.host === '127.0.0.1:8081' ? 'joinexx.space' : location.host;
// let host = location.host === '127.0.0.1:8081' ? 'genumm.com' : location.host;
// host = (host === 'marketingadmin.joinexx.space' || host === 'joinexx.space') ? 'joinexx.space' : 'genumm.com';

// if(location.host === 'marketingadmin.joinexx.space') {
//   host = 'joinexx.space';
// }
//
// if(location.host === 'vbp3migz3gep42z56zhjvbhuwdhyoaece6w3lyswzzlzo4okhzwr5jj.adnl') {
//   host = 'joinexx.space';
// }
//
// if(location.host === 'genumm.ton') {
//   host = 'genumm.com';
// }

// export const MARKETING_API_URL = `https://marketing.${host}/marketing/api/v1/`;
// export const MARKETING_HOST_URL = `https://marketing.${host}`;
// export const MARKETING_API_URL =  '/marketing/api/v1/';

let backendBaseUrl = '';
if (location.hostname === 'localhost') {
  backendBaseUrl = 'https://marketing.joinexx.space/marketing/api/v1/';
  // backendBaseUrl = 'http://localhost:8000/';
} else if (location.hostname === 'marketingadmin.genumm.com') {
  backendBaseUrl = 'https://marketing.genumm.com/marketing/api/v1/';
} else if (location.hostname === 'marketingadmin.joinexx.space') {
  backendBaseUrl = 'https://marketing.joinexx.space/marketing/api/v1/';
} else if (location.hostname.endsWith('.ngrok-free.app')) {
  // backendBaseUrl = 'https://98de-158-181-215-243.ngrok-free.app';
  backendBaseUrl = 'https://marketing.joinexx.space/marketing/api/v1/';
}

const marketingApiAxios = axios.create({
  baseURL: backendBaseUrl,
});

const AUTH_ERRORS = ['Signature verification failed', 'Could not validate credentials', 'Invalid refersh token', 'Signature has expired', 'Password has been changed', 'Missing Authorization Header', 'Invalid crypto padding'];

async function doRequestWithParse(config) {
  return await marketingApiAxios(config).then(result => {
    return result.data;
  }).catch(error=>{
    if(error && error.response && error.response.status === 401 && (AUTH_ERRORS.includes(error.response.data.message))) {
      doLogout();
      location.reload();
      // doRefreshToken().then(response => {
      //   setToken(response.token);
      //   location.reload();
      // })
    } else if(error && error.response?.data?.message) {
      notify({
        type: 'error', text: error.response.data.message
      });
    } else {
      notify({
        type: 'error', text: error
      })
    }
  });
}

export function setToken(token) {
  localStorage.setItem('token', token);

  marketingApiAxios.defaults.headers["Authorization"] = `Bearer ${token}`;
}

const token = localStorage.getItem('token');
if(token){
  setToken(token);
}

export async function doLogin(params) {
  return await doRequestWithParse({
    method: "POST",
    url:"/admin/sign-in",
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'X-2FA-Token': params.code
    },
    data: qs.stringify(params)
  })
}

export async function getCode(params) {
  return await doRequestWithParse({
    method: "POST",
    url:"/admin/create-2fa-token",
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(params)
  })
}

export async function getUsers(params) {
  return await doRequestWithParse({
    method: "GET",
    url:"/admin/users",
    params
  })
}

export async function getAccounts(params) {
  return await doRequestWithParse({
    method: "GET",
    url:"/admin/accounts",
    params
  })
}

export async function getUserLevels(params) {
  return await doRequestWithParse({
    method: "GET",
    url:"/admin/user-levels",
    params
  })
}

export async function getTransactions(params) {
  return await doRequestWithParse({
    method: "GET",
    url:"/admin/transactions",
    params
  })
}

export async function getNews() {
  return await doRequestWithParse({
    method: "GET",
    url:"/admin/news"
  })
}

export async function deleteNews(index) {
  return await doRequestWithParse({
    method: "DELETE",
    url:`/admin/news/${index}`,
  })
}

export async function getNewsByIndex(index) {
  return await doRequestWithParse({
    method: "GET",
    url:`/admin/news/multilingual-by-index/${index}`
  })
}

export async function getWeeksResults(params) {
  return await doRequestWithParse({
    method: "GET",
    url:`/admin/financial-weeks-results`,
    params
  })
}

export async function createNews(data) {
  return await doRequestWithParse({
    method: "POST",
    url:"/admin/news",
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
}

export async function editNews(index, data) {
  return await doRequestWithParse({
    method: "PUT",
    url:`/admin/news/${index}`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
}

export async function uploadImage(formData) {
  return await doRequestWithParse({
    method: "POST",
    url:"/admin/news/upload-image",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: formData
  })
}

export async function translateText(data) {
  return await doRequestWithParse({
    method: "POST",
    url:"/admin/translate",
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  })
}

export async function getPricingPlans() {
  return await doRequestWithParse({
    method: "GET",
    url:"/pricing-plans"
  })
}

export async function doLogout() {
  setToken('');
}

export function marketingUrl(path) {
  return new URL(path, backendBaseUrl).toString();
}
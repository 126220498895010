<template>
    <v-container fluid class="ma-4">
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :page="page"
                :search="search"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                                v-model="search.id"
                                  variant="outlined"
                                  hide-details
                                  type="number"
                                  placeholder="Search id..."
                                  class="ma-1"
                                  @input="loadItems"
                                  density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.first_name"
                                hide-details
                                variant="outlined"
                                placeholder="Search name..."
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                </v-row>
            </template>
            <template v-slot:[`item.user.id`]="{ item }">
                <router-link :to="`/users?id=${item.selectable.user.id}`">{{ item.selectable.user.id }}</router-link>
            </template>
            <template v-slot:[`item.user.first_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.first_name}`">{{ item.selectable.user.first_name }}</router-link>
            </template>
            <template v-slot:[`item.user.last_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.last_name}`">{{ item.selectable.user.last_name }}</router-link>
            </template>
            <template v-slot:[`item.max_level_closed_at`]="{ item }">
                {{ moment(item.selectable.max_level_closed_at * 1000).format('DD.MM.YYYY') }} {{ moment(item.selectable.max_level_closed_at * 1000).format('HH:mm') }}
            </template>
            <template v-slot:[`item.ww_share`]="{ item }">
                {{ item.selectable.ww_share }} %
            </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
  import { getUserLevels } from "../plugins/api-service";
  import moment from 'moment';

  export default {
    name: 'UsersTransactions',
    data: () => ({
      itemsPerPage: 15,
      headers: [
        { title: 'ID', align: 'start', key: 'user.id' },
        { title: 'Имя', key: 'user.first_name', align: 'start', sortable: false },
        { title: 'Фамилия', key: 'user.last_name', align: 'start', sortable: false },
        { title: 'Макс статус', key: 'max_level.id', value: 'max_level.full_title', align: 'start' },
        { title: 'Закрыт', key: 'max_level_closed_at', align: 'start', sortable: false },
        { title: 'Сейчас PV', key: 'current_pv', align: 'start' },
        { title: 'Предыдущая неделя', key: 'last_week_pv', align: 'start'},
        { title: 'Предыдущие 4 недели', key: 'last_four_weeks_pv', align: 'start' },
        { title: 'Points', key: 'points', align: 'start', sortable: false, },
        { title: 'Доля WW', key: 'ww_share', align: 'start', sortable: false, },
      ],
      serverItems: [],
      loading: true,
      totalItems: 9999,
      page: 1,
      search: {
        id: null,
        first_name: '',
        email: ''
      },
      sort: {}
    }),
    mounted() {
      this.loadItems()
    },
    methods: {
      moment,
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        this.loading = true;

        let page = params?.page || 1;

        let requestParams = {
          offset: (page - 1) * (params?.itemsPerPage || this.itemsPerPage),
          limit: this.itemsPerPage === -1 ? this.totalItems : this.itemsPerPage,
          user_id: this.search.id || this.$route.params.id,
          order_direction: 'desc'
        };

        if(this.search.first_name) {
          requestParams.user_name_contains = this.search.first_name;
        }

        if(this.search.email) {
          requestParams.email_contains = this.search.email;
        }

        if(this.sort.order_direction && this.sort.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }

        if(params?.order_direction && params?.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }


        await getUserLevels(requestParams).then(response=>{
          if(response?.success) {
            this.serverItems = response.result.items;
            this.totalItems = response.result.total;
            this.loading = false;
          }
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
    .adm-container {
        min-height: calc(100vh - 64px);
    }
</style>
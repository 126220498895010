<template>
  <router-view :key="$route.fullPath"/>
  <notifications position="bottom right" width="auto"></notifications>
</template>

<script>
  // import { getAccounts, getTransactions, getUsers, getUserLevels } from "./plugins/api-service";

  export default {
    name: 'App',
    async mounted() {
       // await getCode({ username: 'anton', password: 'test'});
      // this.$store.dispatch('user/doLogin', {
      //   username: 'anton', password: 'test', code: 587441
      // });
      // let accounts = await getAccounts({user_id: 41});
      // let transactions = await getTransactions({user_id: 41});
      // let users = await getUsers({id: 41});
      // let userLevels = await getUserLevels({user_id: 41});
      //
      // console.log('accounts', accounts);
      // console.log('transactions', transactions);
      // console.log('users', users);
      // console.log('userLevels', userLevels);
    }
  };
</script>
